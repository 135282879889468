/* eslint-disable no-undef */
import React from "react"
import StructurePageCyC from "@components/pageCasosdeExito/template/templatePageCyC"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: C&C [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/cyccomputer/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Descubre cómo C&C ha aumentado sus ventas digitales en más del 40% con la ayuda de Beex." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: C&C" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/cyccomputer/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-cyc.jpg`}
      />
      <meta property="og:description" content="Descubre cómo C&C ha aumentado sus ventas digitales en más del 40% con la ayuda de Beex." />
    </Helmet>
    <StructurePageCyC location={location} />
  </div>
)

export default IndexPage
